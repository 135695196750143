var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('woot-modal',{attrs:{"show":_vm.showCatalogModal,"on-close":function () { return _vm.$emit('on-close'); }}},[_c('woot-modal-header',[_c('ul',{staticClass:"tabs"},[_c('li',{class:{
          'tabs-title': true,
          'active-tab': _vm.activeTab === 'spreadsheet',
        }},[_c('a',{on:{"click":function () { return _vm.setActiveTab('spreadsheet'); }}},[_c('span',[_vm._v("\n            "+_vm._s(_vm.$t(
                'INTEGRATION_APPS.MODAL_CATALOG_INSERT_DATA.LOAD_SPREADSHEET'
              ))+"\n          ")])])]),_vm._v(" "),_c('li',{class:{
          'tabs-title': true,
          'active-tab': _vm.activeTab === 'link',
        }},[_c('a',{on:{"click":function () { return _vm.setActiveTab('link'); }}},[_c('span',[_vm._v("\n            "+_vm._s(_vm.$t('INTEGRATION_APPS.MODAL_CATALOG_INSERT_DATA.INSERT_LINK'))+"\n          ")])])])])]),_vm._v(" "),(_vm.activeTab === 'spreadsheet')?_c('p',[_vm._v("\n    "+_vm._s(_vm.$t('INTEGRATION_APPS.IMPORT_FILE.DESC') + ' ')+"\n    "),_c('a',{staticClass:"btn-primary",attrs:{"href":_vm.xmlUrl,"download":"catalog.xml"}},[_vm._v(" XML |")]),_vm._v(" "),_c('a',{staticClass:"btn-primary",attrs:{"href":_vm.xlsxUrl,"download":"catalog.xlsx"}},[_vm._v(" XLSX")])]):_vm._e(),_vm._v(" "),(_vm.activeTab === 'link')?_c('p',[_vm._v("\n    "+_vm._s(_vm.$t('INTEGRATION_APPS.IMPORT_FILE.DESC') + ' ')+"\n    "),_c('a',{staticClass:"btn-primary",attrs:{"href":_vm.xmlUrl,"download":"catalog.xml"}},[_vm._v(" XML")])]):_vm._e(),_vm._v(" "),(_vm.activeTab === 'spreadsheet')?_c('div',{staticClass:"file-upload-container"},[_c('label',{staticClass:"file-upload-button",attrs:{"for":"file-upload"}},[_vm._v("\n      "+_vm._s(_vm.$t('INTEGRATION_APPS.MODAL_CATALOG_INSERT_DATA.SELECT_FILE'))+"\n    ")]),_vm._v(" "),_c('input',{attrs:{"id":"file-upload","type":"file","accept":".xlsx, .xml"},on:{"change":_vm.triggerParentMethod}})]):_vm._e(),_vm._v(" "),(_vm.activeTab === 'link')?_c('div',{staticClass:"box-upload"},[_c('woot-input',{attrs:{"placeholder":_vm.$t(
          'INTEGRATION_APPS.MODAL_CATALOG_INSERT_DATA.PLACEHOLDER_INPUT_LINK'
        )},model:{value:(_vm.localLinkDownloadFile),callback:function ($$v) {_vm.localLinkDownloadFile=$$v},expression:"localLinkDownloadFile"}}),_vm._v(" "),_c('woot-button',{on:{"click":function($event){$event.preventDefault();return _vm.handleFetchFile($event)}}},[_vm._v("\n      "+_vm._s(_vm.$t('INTEGRATION_APPS.MODAL_CATALOG_INSERT_DATA.BUTTON'))+"\n    ")])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
export const getters = {
  getProducts($state) {
    return $state.sortOrder.map(productId => $state.records[productId]);
  },
  getMeta($state) {
    return $state.meta;
  },
  getProduct: $state => id => {
    const product = $state.records[id];
    return product || {};
  },
  getUIFlags($state) {
    return $state.uiFlags;
  },
};

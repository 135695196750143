<template>
  <woot-modal
    :show.sync="showTemplateModal"
    :on-close="() => $emit('on-close')"
  >
    <woot-modal-header>
      <catalog-product-table
        :catalog-product-headers="catalogProductHeaders"
        :show-search-empty-state="showSearchEmptyState"
        :is-loading="isLoading"
        :selected-hook="selectedHook"
        :current-page="currentPage"
        :total-count="totalCount"
        :page-size="pageSize"
        @edit-product="openEditProduct"
        @deleted-product="deletedProduct"
      />
    </woot-modal-header>

    <woot-modal :show.sync="showModalEditProduct" :on-close="closeEditProduct">
      <woot-modal-header :header-title="showNameProduct" />
      <edit-product :catalog-product="currentProduct" />
    </woot-modal>
  </woot-modal>
</template>

<script>
import CatalogProductTable from './CatalogProductTable.vue';
import EditProduct from './EditProduct.vue';

export default {
  components: {
    CatalogProductTable,
    EditProduct,
  },
  props: {
    catalogProductHeaders: {
      type: Array,
      default() {
        return [];
      },
    },
    showSearchEmptyState: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    showTemplateModal: {
      type: Boolean,
      default: false,
    },
    selectedHook: {
      type: Object,
      default: {},
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    totalCount: {
      type: Number,
      default: 20,
    },
    pageSize: {
      type: Number,
      default: 10,
    },
  },
  data() {
    return {
      showModalEditProduct: false,
      currentProduct: {},
      alertMessage: '',
    };
  },
  computed: {
    showNameProduct() {
      return `${this.$t(
        'INTEGRATION_APPS.LIST_CATALOG_PRODUCT.EDIT_PRODUCT'
      )} - ${this.currentProduct?.name_product}`;
    },
  },
  methods: {
    openEditProduct(currentItem) {
      this.currentProduct = currentItem;
      this.showModalEditProduct = true;
    },
    async deletedProduct(item) {
      await this.$store.dispatch('integrations/deleteCatalogProductBase', item);
      this.closeEditProduct();
    },
    closeEditProduct() {
      this.showModalEditProduct = false;
    },
  },
};
</script>

<style scoped></style>

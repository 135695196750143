var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"small-8 columns with-right-space"},[(_vm.hasConnectedHooks)?_c('table',{staticClass:"woot-table"},[_c('thead',[_vm._l((_vm.hookHeaders),function(hookHeader){return _c('th',{key:hookHeader},[_vm._v("\n          "+_vm._s(hookHeader)+"\n        ")])}),_vm._v(" "),(_vm.isHookTypeInbox)?_c('th',[_vm._v("\n          "+_vm._s(_vm.$t("INTEGRATION_APPS.LIST.INBOX"))+"\n        ")]):_vm._e()],2),_vm._v(" "),_c('tbody',_vm._l((_vm.hooks),function(hook){return _c('tr',{key:hook.id},[_vm._l((hook.properties),function(property){return _c('td',{key:property,staticClass:"hook-item"},[_vm._v("\n            "+_vm._s(property)+"\n          ")])}),_vm._v(" "),(_vm.isHookTypeInbox)?_c('td',{staticClass:"hook-item"},[_vm._v("\n            "+_vm._s(_vm.inboxName(hook))+"\n          ")]):_vm._e(),_vm._v(" "),_c('td',{staticClass:"button-wrapper"},[_c('woot-button',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:(_vm.$t('INTEGRATION_APPS.LIST.KNOWLEDGE_BASE.BUTTON_TEXT')),expression:"$t('INTEGRATION_APPS.LIST.KNOWLEDGE_BASE.BUTTON_TEXT')",modifiers:{"top":true}}],attrs:{"variant":"smooth","color-scheme":"alert","size":"tiny","icon":"document","class-names":"success"},on:{"click":function($event){return _vm.openListModal(hook)}}}),_vm._v(" "),_c('woot-button',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:(_vm.$t('INTEGRATION_APPS.LIST.DELETE.BUTTON_TEXT')),expression:"$t('INTEGRATION_APPS.LIST.DELETE.BUTTON_TEXT')",modifiers:{"top":true}}],attrs:{"variant":"smooth","color-scheme":"alert","size":"tiny","icon":"dismiss-circle","class-names":"grey-btn"},on:{"click":function($event){return _vm.$emit('delete', hook)}}})],1)],2)}),0)]):_c('p',{staticClass:"no-items-error-message"},[_vm._v("\n      "+_vm._s(_vm.$t("INTEGRATION_APPS.NO_HOOK_CONFIGURED", {
          integrationId: this.labelContentEmpty,
      }))+"\n    ")])]),_vm._v(" "),_c('div',{staticClass:"small-4 columns"},[_c('p',[_c('b',[_vm._v(_vm._s(_vm.integration.name))])]),_vm._v(" "),_c('p',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html",value:(
                  _vm.$t(
                    ("INTEGRATION_APPS.SIDEBAR_DESCRIPTION." + (_vm.integration.name.toUpperCase())),
                    { installationName: _vm.globalConfig.installationName }
                  )
                ),expression:"\n                  $t(\n                    `INTEGRATION_APPS.SIDEBAR_DESCRIPTION.${integration.name.toUpperCase()}`,\n                    { installationName: globalConfig.installationName }\n                  )\n                "}]})]),_vm._v(" "),(_vm.showListModal)?_c('div',{on:{"click":_vm.closeListModal}},[_c('woot-modal',{attrs:{"show":_vm.showListModal,"on-close":_vm.closeListModal,"persistent":true,"size":"modal-medium"},on:{"update:show":function($event){_vm.showListModal=$event},"click":function($event){$event.stopPropagation();}}},[(_vm.showListModal)?_c('knowledge-base-list',{staticClass:"custom-modal-size",attrs:{"hook":_vm.selectedHook},on:{"on-send":_vm.onSendListMessage,"cancel":_vm.closeListModal,"on-submit":_vm.onSendListMessage}}):_vm._e()],1)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <ve-table
      v-if="tableData.length"
      class="containerTableCatalog"
      :fixed-header="true"
      scroll-width="90rem"
      scroll-height="100rem"
      :columns="columns"
      :table-data="tableData"
      :border-around="false"
      :sort-option="sortOption"
      row-key-field-name="id"
    />
    <empty-state
      v-if="showSearchEmptyState"
      :title="$t('INTEGRATION_APPS.LIST_CATALOG_PRODUCT.404')"
    />
    <empty-state
      v-else-if="!isLoading && !tableData.length"
      :title="$t('INTEGRATION_APPS.LIST_CATALOG_PRODUCT.NO_PRODUCT')"
    />
    <div v-if="isLoading" class="contacts--loader">
      <spinner />
      <span>{{
        $t('INTEGRATION_APPS.LIST_CATALOG_PRODUCT.LOADING_MESSAGE')
      }}</span>
    </div>

    <table-footer
      v-if="tableData.length"
      :current-page="localCurrentPage"
      :total-count="totalCount"
      :page-size="pageSize"
      @page-change="onPageChange"
    />
  </div>
</template>

<script>
import Spinner from 'shared/components/Spinner.vue';
import EmptyState from 'dashboard/components/widgets/EmptyState.vue';
import { VeTable } from 'vue-easytable';
import TableFooter from 'dashboard/components/widgets/TableFooter';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    EmptyState,
    Spinner,
    VeTable,
    TableFooter,
  },
  props: {
    catalogProductHeaders: {
      type: Array,
      default: () => [],
    },
    showSearchEmptyState: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    selectedHook: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      sortOption: {
        sortAlways: true,
      },
      columns: this.getColumns(),
      localCurrentPage: 1,
    };
  },
  computed: {
    tableData() {
      return [...this.$store.getters['integrations/getCatalogProduct']];
    },
    currentPagee() {
      return this.paginations()?.currentPage;
    },
    totalCount() {
      return this.paginations().totalItems;
    },
    pageSize() {
      return this.paginations().pageSize;
    },
  },
  created() {
    this.fetchCatalogProductBases({
      page: this.currentPage,
      hookId: this.selectedHook.id,
    });
  },
  methods: {
    ...mapActions({
      fetchCatalogProductBases: 'integrations/fetchCatalogProductBases',
    }),

    ...mapGetters({
      paginations: 'integrations/paginations',
    }),
    getColumns() {
      return [
        {
          field: 'name_product',
          key: 'name_product',
          width: 15,
          title: this.$t('INTEGRATION_APPS.LIST_CATALOG_PRODUCT.NAME_PRODUCT'),
          align: this.isRTLView ? 'right' : 'left',
        },
        {
          field: 'url_image',
          key: 'url_image',
          width: 15,
          title: this.$t('INTEGRATION_APPS.LIST_CATALOG_PRODUCT.IMAGE_PRODUCT'),
          align: this.isRTLView ? 'right' : 'left',
        },
        {
          field: 'link_product',
          key: 'link_product',
          width: 15,
          title: this.$t('INTEGRATION_APPS.LIST_CATALOG_PRODUCT.URL_PRODUCT'),
          align: this.isRTLView ? 'right' : 'left',
        },
        {
          field: 'id',
          key: 'id',
          fixed: 'right',
          width: 5,
          title: ' ',
          align: 'center',
          renderBodyCell: ({ row }) => (
            <div>
              <woot-button
                onClick={() => this.$emit('edit-product', row)}
                color-scheme="success"
                class="paddingIcon"
                icon="edit"
                size="tiny"
              />
              <woot-button
                onClick={() => this.$emit('deleted-product', row)}
                color-scheme="alert"
                icon="delete"
                size="tiny"
              />
            </div>
          ),
        },
      ];
    },
    onPageChange(newPage) {
      this.localCurrentPage = newPage;
      this.fetchCatalogProductBases({
        page: newPage,
        hookId: this.selectedHook.id,
      }).then(() => {
        this.currentPage = newPage;
      });
    },
  },
};
</script>
<style scoped>
.button.button--only-icon.tiny {
  margin-right: 5px;
}
.containerTableCatalog {
  width: 100%;
  height: 60vh;
  margin-top: 2rem;
}
.containerTableCatalog {
  width: 100%;
  height: 60vh;
  margin-top: 2rem;
  table-layout: fixed;
}

.ve-table {
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
}

.ve-table td {
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
}
</style>

/* eslint no-param-reassign: 0 */
import Vue from 'vue';
import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import * as types from '../mutation-types';
import IntegrationsAPI from '../../api/integrations';

const state = {
  records: [],
  meta: {
    count: 0,
    currentPage: 1,
  },
  pagination: {
    totalItems: 0,
    currentPage: 1,
    pageSize: 10,
    totalPages: 0,
  },
  catalogProductBases: [],
  uiFlags: {
    isCreating: false,
    isFetching: false,
    isFetchingItem: false,
    isUpdating: false,
    isCreatingHook: false,
    isDeletingHook: false,
    isCreatingKnowledgeBase: false,
    isUpdatingKnowledgeBase: false,
    isDeletingKnowledgeBase: false,
    isCreatingCatalogProductBase: false,
    isUpdatingCatalogProductBase: false,
    isDeletingCatalogProductBase: false,
  },
};

const isAValidAppIntegration = integration => {
  return [
    'dialogflow',
    'dyte',
    'google_translate',
    'openai',
    'omnibot',
    'catalog',
  ].includes(integration.id);
};
export const getters = {
  getCatalogProduct($state) {
    return $state.catalogProductBases;
  },
  paginations($state) {
    return $state.pagination;
  },
  isFetching: $state => $state.uiFlags.isFetching,

  getMeta: $state => {
    return $state.meta;
  },

  getIntegrations($state) {
    return $state.records.filter(item => !isAValidAppIntegration(item));
  },
  getAppIntegrations($state) {
    return $state.records.filter(item => isAValidAppIntegration(item));
  },
  getIntegration: $state => integrationId => {
    const [integration] = $state.records.filter(
      record => record.id === integrationId
    );
    return integration || {};
  },
  getKnowledgeBaseData: ({ records }) => hookId => {
    const record = records.find(record => record.id === 'omnibot');
    if (record) {
      const hook = record.hooks.find(hook => hook.id === hookId);
      if (hook) {
        return hook.knowledge_bases;
      }
    }
    return [];
  },
  getCatalogProductBaseData: ({ records }) => hookId => {
    const record = records.find(i => i.id === 'catalog');
    if (record) {
      const hook = record.hooks.find(item => {
        return item.id === hookId;
      });
      if (hook) {
        return hook.catalog_product_bases;
      }
    }
    return [];
  },
  getUIFlags($state) {
    return $state.uiFlags;
  },
};

export const actions = {
  get: async ({ commit }) => {
    commit(types.default.SET_INTEGRATIONS_UI_FLAG, { isFetching: true });
    try {
      const response = await IntegrationsAPI.get();
      commit(types.default.SET_INTEGRATIONS, response.data.payload);
      commit(types.default.SET_INTEGRATIONS_UI_FLAG, { isFetching: false });
    } catch (error) {
      commit(types.default.SET_INTEGRATIONS_UI_FLAG, { isFetching: false });
    }
  },

  connectSlack: async ({ commit }, code) => {
    commit(types.default.SET_INTEGRATIONS_UI_FLAG, { isUpdating: true });
    try {
      const response = await IntegrationsAPI.connectSlack(code);
      commit(types.default.ADD_INTEGRATION, response.data);
      commit(types.default.SET_INTEGRATIONS_UI_FLAG, { isUpdating: false });
    } catch (error) {
      commit(types.default.SET_INTEGRATIONS_UI_FLAG, { isUpdating: false });
    }
  },
  createKnowledgeBase: async ({ commit }, basePayload) => {
    commit(types.default.SET_INTEGRATIONS_UI_FLAG, {
      isCreatingKnowledgeBase: true,
    });
    try {
      const response = await IntegrationsAPI.createKnowledgeBase(basePayload);
      commit(types.default.ADD_INTEGRATION_KNOWLEDGE_BASES, response.data);
      commit(types.default.SET_INTEGRATIONS_UI_FLAG, {
        isCreatingKnowledgeBase: false,
      });
    } catch (error) {
      commit(types.default.SET_INTEGRATIONS_UI_FLAG, {
        isCreatingKnowledgeBase: false,
      });
      throw new Error(error);
    }
  },
  updateKnowledgeBase: async ({ commit }, basePayload) => {
    commit(types.default.SET_INTEGRATIONS_UI_FLAG, {
      isUpdatingKnowledgeBase: true,
    });
    try {
      const response = await IntegrationsAPI.updateKnowledgeBase(basePayload);
      commit(types.default.UPDATE_INTEGRATION_KNOWLEDGE_BASES, response.data);
      commit(types.default.SET_INTEGRATIONS_UI_FLAG, {
        isUpdatingKnowledgeBase: false,
      });
    } catch (error) {
      commit(types.default.SET_INTEGRATIONS_UI_FLAG, {
        isUpdatingKnowledgeBase: false,
      });
      throw new Error(error);
    }
  },
  deleteKnowledgeBase: async ({ commit }, knowledgeBase) => {
    commit(types.default.SET_INTEGRATIONS_UI_FLAG, { isDeletingHook: true });
    try {
      await IntegrationsAPI.deleteKnowledgeBase(knowledgeBase.id);
      commit(types.default.DELETE_INTEGRATION_KNOWLEDGE_BASES, knowledgeBase);
      commit(types.default.SET_INTEGRATIONS_UI_FLAG, { isDeletingHook: false });
    } catch (error) {
      commit(types.default.SET_INTEGRATIONS_UI_FLAG, { isDeletingHook: false });
      throw new Error(error);
    }
  },
  deleteIntegration: async ({ commit }, integrationId) => {
    commit(types.default.SET_INTEGRATIONS_UI_FLAG, { isDeleting: true });
    try {
      await IntegrationsAPI.delete(integrationId);
      commit(types.default.DELETE_INTEGRATION, {
        id: integrationId,
        enabled: false,
      });
      commit(types.default.SET_INTEGRATIONS_UI_FLAG, { isDeleting: false });
    } catch (error) {
      commit(types.default.SET_INTEGRATIONS_UI_FLAG, { isDeleting: false });
    }
  },
  createHook: async ({ commit }, hookData) => {
    commit(types.default.SET_INTEGRATIONS_UI_FLAG, { isCreatingHook: true });
    try {
      const response = await IntegrationsAPI.createHook(hookData);
      commit(types.default.ADD_INTEGRATION_HOOKS, response.data);
      commit(types.default.SET_INTEGRATIONS_UI_FLAG, { isCreatingHook: false });
      return response.data;
    } catch (error) {
      commit(types.default.SET_INTEGRATIONS_UI_FLAG, { isCreatingHook: false });
      throw new Error(error);
    }
  },
  deleteHook: async ({ commit }, { appId, hookId }) => {
    commit(types.default.SET_INTEGRATIONS_UI_FLAG, { isDeletingHook: true });
    try {
      await IntegrationsAPI.deleteHook(hookId);
      commit(types.default.DELETE_INTEGRATION_HOOKS, { appId, hookId });
      commit(types.default.SET_INTEGRATIONS_UI_FLAG, { isDeletingHook: false });
    } catch (error) {
      commit(types.default.SET_INTEGRATIONS_UI_FLAG, { isDeletingHook: false });
      throw new Error(error);
    }
  },
  updateHookeSettings: async ({commit},data) =>{
    commit(types.default.SET_INTEGRATIONS_UI_FLAG, { isDeletingHook: true });
    try {
      const payloadCatalog = {page:1, hookId: null, name:''}
      await IntegrationsAPI.updateHookSettings(data.hookId, data.data);
      commit(types.default.SET_INTEGRATIONS_UI_FLAG, { isDeletingHook: false });
      const response = await IntegrationsAPI.get();

      commit(types.default.SET_INTEGRATIONS, response.data.payload);
    } catch (error) {
      commit(types.default.SET_INTEGRATIONS_UI_FLAG, { isDeletingHook: false });
    }
  },
  async fetchCatalogProductBases({ commit }, { page, hookId = null, name = '' }) {
    commit('SET_IS_FETCHING', true);
    try {
      const response = await IntegrationsAPI.getCatalogProductBases(
        page,
        hookId,
        name
      );
      const { total_count, catalog_product_bases } = response.data;

      commit(
        types.default.ADD_INTEGRATION_CATALOG_PRODUCT_BASES,
        catalog_product_bases
      );

      commit(types.default.SET_PAGINATION, {
        totalItems: total_count,
        currentPage: page,
      });
    } catch (error) {
      console.log('Erro ao buscar produtos:', error);
    } finally {
      commit('SET_IS_FETCHING', false);
    }
  },
  createCatalogProductBase: async ({ commit }, basePayload) => {
    commit(types.default.SET_INTEGRATIONS_UI_FLAG, {
      isCreatingCatalogProductBase: true,
    });
    try {
      const response = await IntegrationsAPI.createCatalogProductBase(
        basePayload
      );
      const itemsToCommit = response?.data?.data;

      commit(
        types.default.ADD_INTEGRATION_CATALOG_PRODUCT_BASES,
        itemsToCommit
      );

      commit(types.default.SET_INTEGRATIONS_UI_FLAG, {
        isCreatingCatalogProductBase: false,
      });
    } catch (error) {
      commit(types.default.SET_INTEGRATIONS_UI_FLAG, {
        isCreatingCatalogProductBase: false,
      });
      throw new Error(error);
    }
  },
  updateCatalogProductBase: async ({ commit }, basePayload) => {
    commit(types.default.SET_INTEGRATIONS_UI_FLAG, {
      isUpdatingCatalogProductBase: true,
    });
    try {
      const response = await IntegrationsAPI.updateCatalogProductBase(
        basePayload
      );
      commit(
        types.default.UPDATE_INTEGRATION_CATALOG_PRODUCT_BASES,
        response.data
      );
      commit(types.default.SET_INTEGRATIONS_UI_FLAG, {
        isUpdatingCatalogProductBase: false,
      });
    } catch (error) {
      commit(types.default.SET_INTEGRATIONS_UI_FLAG, {
        isUpdatingCatalogProductBase: false,
      });
      throw new Error(error);
    }
  },
  deleteCatalogProductBase: async ({ commit }, catalogProductBase) => {
    commit(types.default.SET_INTEGRATIONS_UI_FLAG, {
      isDeletingCatalogProductBase: true,
    });
    try {
      await IntegrationsAPI.deleteCatalogProductBase(catalogProductBase.id);
      commit(
        types.default.DELETE_INTEGRATION_CATALOG_PRODUCT_BASES,
        catalogProductBase
      );
      commit(types.default.SET_INTEGRATIONS_UI_FLAG, {
        isDeletingCatalogProductBase: false,
      });
    } catch (error) {
      commit(types.default.SET_INTEGRATIONS_UI_FLAG, {
        isDeletingCatalogProductBase: false,
      });
      throw new Error(error);
    }
  },
};

export const mutations = {
  [types.default.SET_CATALOG_PRODUCT_BASES]: (
    $state,
    { catalogProductBases, totalItems }
  ) => {
    if (pagination.currentPage === 1) {
      $state.catalogProductBases = catalogProductBases;
    } else {
      $state.catalogProductBases = [
        ...$state.catalogProductBases,
        ...catalogProductBases,
      ];
    }
    $state.pagination.totalItems = totalItems;
  },
  [types.default.SET_PAGINATION]: ($state, { totalItems, currentPage }) => {
    $state.pagination.totalItems = totalItems;
    $state.pagination.currentPage = currentPage;
  },
  [types.default.SET_IS_FETCHING]: ($state, isFetching) => {
    $state.uiFlags.isFetching = isFetching;
  },
  [types.default.SET_INTEGRATIONS_UI_FLAG]($state, uiFlag) {
    $state.uiFlags = { ...$state.uiFlags, ...uiFlag };
  },
  [types.default.SET_INTEGRATIONS]: MutationHelpers.set,
  [types.default.ADD_INTEGRATION]: MutationHelpers.updateAttributes,
  [types.default.DELETE_INTEGRATION]: MutationHelpers.updateAttributes,
  [types.default.ADD_INTEGRATION_HOOKS]: ($state, data) => {
    $state.records.forEach((element, index) => {
      if (element.id === data.app_id) {
        const record = $state.records[index];
        Vue.set(record, 'hooks', [...record.hooks, data]);
      }
    });
  },
  [types.default.ADD_INTEGRATION_KNOWLEDGE_BASES]: ($state, data) => {
    $state.records.forEach((element, index) => {
      if (element.id === 'omnibot') {
        element.hooks.forEach((hook, hookIndex) => {
          if (hook.id === data.integrationId) {
            const record = $state.records[index].hooks[hookIndex];
            Vue.set(record, 'knowledge_bases', [
              ...record.knowledge_bases,
              data,
            ]);
            Vue.set(
              record,
              'total_baseai_characters',
              record.total_baseai_characters + data.baseAI.length
            );
          }
        });
      }
    });
  },
  [types.default.UPDATE_INTEGRATION_KNOWLEDGE_BASES]: ($state, data) => {
    const omnibot = {
      index: $state.records.findIndex(record => record.id === 'omnibot'),
      value: $state.records.find(record => record.id === 'omnibot'),
    };
    const hook = {
      index: omnibot.value.hooks.findIndex(
        hook => hook.id === data.integrationId
      ),
      value: omnibot.value.hooks.find(hook => hook.id === data.integrationId),
    };
    const knowledgeBase = {
      index: hook.value.knowledge_bases.findIndex(
        knowledgeBase => knowledgeBase.id === data.id
      ),
      value: hook.value.knowledge_bases.find(
        knowledgeBase => knowledgeBase.id === data.id
      ),
    };
    const record = $state.records[omnibot.index].hooks[hook.index];

    Vue.set(record.knowledge_bases, knowledgeBase.index, data);
    Vue.set(
      record,
      'total_baseai_characters',
      record.total_baseai_characters + data.baseAI.length
    );
  },
  [types.default.DELETE_INTEGRATION_KNOWLEDGE_BASES]: (
    $state,
    knowledgeBase
  ) => {
    const omnibot = {
      index: $state.records.findIndex(record => record.id === 'omnibot'),
      value: $state.records.find(record => record.id === 'omnibot'),
    };
    const hook = {
      index: omnibot.value.hooks.findIndex(
        hook => hook.id === knowledgeBase.integrationId
      ),
      value: omnibot.value.hooks.find(
        hook => hook.id === knowledgeBase.integrationId
      ),
    };
    const knowledgeBasesWithoutDeleted = hook.value.knowledge_bases.filter(
      item => item.id !== knowledgeBase.id
    );

    const record = $state.records[omnibot.index].hooks[hook.index];

    Vue.set(record, 'knowledge_bases', knowledgeBasesWithoutDeleted);
  },
  [types.default.DELETE_INTEGRATION_HOOKS]: ($state, { appId, hookId }) => {
    $state.records.forEach((element, index) => {
      if (element.id === appId) {
        const record = $state.records[index];
        const hooksWithoutDeletedHook = record.hooks.filter(
          hook => hook.id !== hookId
        );
        Vue.set(record, 'hooks', hooksWithoutDeletedHook);
      }
    });
  },
  [types.default.SET_CATALOG_PRODUCT_BASES](
    $state,
    { catalogProductBases, pagination }
  ) {
    $state.pagination = pagination;
    const catalogRecord = $state.records.find(
      record => record.id === 'catalog'
    );
    if (catalogRecord) {
      catalogRecord.hooks.forEach(hook => {
        if (hook.id === pagination.hookId) {
          Vue.set(hook, 'catalog_product_bases', catalogProductBases);
        }
      });
    }
  },
  [types.default.ADD_INTEGRATION_CATALOG_PRODUCT_BASES]: ($state, data) => {
    $state.catalogProductBases = [...data];

    $state.records.forEach((element, index) => {
      if (element.id === 'catalog') {
        element.hooks.forEach((hook, hookIndex) => {
          if (hook.id === data[0].integrationId) {
            const record = $state.records[index].hooks[hookIndex];
            Vue.set(record, 'catalog_product_bases', ...data);
          }
        });
      }
    });
  },
  [types.default.UPDATE_INTEGRATION_CATALOG_PRODUCT_BASES]: ($state, data) => {
    const catalog = {
      index: $state.records.findIndex(record => record.id === 'catalog'),
      value: $state.records.find(record => record.id === 'catalog'),
    };
    const currentHook = {
      index: catalog.value.hooks.findIndex(
        hook => hook.id === data.integrationId
      ),
      value: catalog.value.hooks.find(hook => hook.id === data.integrationId),
    };
    const currrentCatalogProductBase = {
      index: currentHook.value.catalog_product_bases.findIndex(
        catalogProductBase => catalogProductBase.id === data.id
      ),
      value: currentHook.value.catalog_product_bases.find(
        catalogProductBase => catalogProductBase.id === data.id
      ),
    };
    const record = $state.records[catalog.index].hooks[currentHook.index];

    Vue.set(
      record.catalog_product_bases,
      currrentCatalogProductBase.index,
      data
    );

  },
  [types.default.DELETE_INTEGRATION_CATALOG_PRODUCT_BASES]: (
    $state,
    catalogProductBase
  ) => {
    const catalog = {
      index: $state.records.findIndex(record => record.id === 'catalog'),
      value: $state.records.find(record => record.id === 'catalog'),
    };
    const currentHook = {
      index: catalog.value.hooks.findIndex(
        hook => hook.id === catalogProductBase.integrationId
      ),
      value: catalog.value.hooks.find(
        hook => hook.id === catalogProductBase.integrationId
      ),
    };
    const catalogProductBasesWithoutDeleted = currentHook.value.catalog_product_bases.filter(
      item => item.id !== catalogProductBase.id
    );

    const record = $state.records[catalog.index].hooks[currentHook.index];
    Vue.set(record, 'catalog_product_bases', catalogProductBasesWithoutDeleted);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

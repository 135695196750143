import Vue from 'vue';

export const mutations = {
  SET_PRODUCT_UI_FLAG($state, data) {
    $state.uiFlags = { ...$state.uiFlags, ...data };
  },

  CLEAR_PRODUCTS($state) {
    Vue.set($state, 'records', {});
    Vue.set($state, 'sortOrder', []);
  },

  SET_PRODUCTS($state, data) {
    data.forEach(product => {
      Vue.set($state.records, product.id, product);
      if (!$state.sortOrder.includes(product.id)) {
        $state.sortOrder.push(product.id);
      }
    });
  },

  SET_PRODUCT_META(
    $state,
    { count, current_page: currentPage, total_pages: totalPages }
  ) {
    Vue.set($state.meta, 'count', count);
    Vue.set($state.meta, 'currentPage', currentPage);
    Vue.set($state.meta, 'totalPages', totalPages);
  },
};
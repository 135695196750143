<template>
  <div class="column content-box">
    <div class="row">
      <div class="empty-wrapper">
        <woot-loading-state
          v-if="uiFlags.isFetching"
          :message="$t('INTEGRATION_APPS.FETCHING')"
        />
      </div>

      <div class="small-12 columns integrations-wrap">
        <div class="row integrations">
          <div
            v-for="item in filteredIntegrations"
            :key="item.id"
            class="small-12 columns integration"
          >
            <integration-item
              :integration-id="item.id"
              :integration-logo="item.logo"
              :integration-name="item.name"
              :integration-description="item.description"
              :integration-enabled="item.hooks.length"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import IntegrationItem from './IntegrationItem';
import agentMixin from 'dashboard/mixins/agentMixin';
import adminMixin from '../../../../mixins/isAdmin';

export default {
  components: {
    IntegrationItem,
  },
  mixins: [agentMixin, adminMixin],
  computed: {
    ...mapGetters({
      uiFlags: 'labels/getUIFlags',
      integrationsList: 'integrations/getAppIntegrations',
    }),
    permissionToViewCatalog() {
      return this.isPermissionManagerCatalogProduct && !this.isAdmin;
    },
    filteredIntegrations() {
      if (this.permissionToViewCatalog) {
        return this.integrationsList.filter(item => item.id === 'catalog');
      }
      return this.integrationsList;
    },
  },
  mounted() {
    this.$store.dispatch('integrations/get');
  },
};
</script>

<style scoped lang="scss">
@import '~dashboard/assets/scss/variables';
.empty-wrapper {
  margin: var(--space-zero) auto;
}
</style>

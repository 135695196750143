<template>
  <div>
    <modal-file-or-url :show-catalog-modal="showCatalogModal" @on-close="onClose" @on-file-change="handleFileChange"
      @on-fetch-file="fetchFile" />

    <modal-select-column-catalog v-if="selectRepresentativeColumnName || isErrorLoadingFile" :file-name="fileName"
      :columns-name="fileColumns" :type-file="typeFile" :integration="integration"
      :content-of-uploaded-file="uploadFiles" :show-catalog-modal="showCatalogModal"
      :is-upload-in-progress="isUploadInProgress" :is-error-loading-file="isErrorLoadingFile"
      :not-espace-in-disc="notEspaceInDisc" @on-close="onClose" @set-is-upload-in-progress="setIsUploadInProgress"
      @set-is-error-loading-file="setIsErrorLoadingFile" @set-not-espace-in-disc="setNotEspaceInDisc" />
  </div>
</template>

<script>
import ModalSelectColumnCatalog from '../catalog/ModalSelectColumnCatalog.vue';
import ModalFileOrUrl from '../catalog/ModalFileOrUrl.vue';
import * as XLSX from 'xlsx';
import alertMixin from 'shared/mixins/alertMixin';
import integrations from '../../../../../api/integrations';

export default {
  mixin: {
    alertMixin,
  },
  components: {
    ModalSelectColumnCatalog,
    ModalFileOrUrl,
  },
  props: {
    showCatalogModal: {
      type: Boolean,
      default: true,
    },
    integration: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      activeTab: 'link',
      file: [],
      typeFile: 'xlsx',
      fileColumns: [],
      contentOfUploadedFile: [],
      fileName: '',
      notEspaceInDisc: false,
      isErrorLoadingFile: false,
      isUploadInProgress: false,
      uploadFiles: [],
    };
  },

  computed: {
    insertFileorLink() {
      return this.fileColumns.length === 0;
    },
    selectRepresentativeColumnName() {
      return this.fileColumns.length;
    },
  },
  methods: {
    setActiveTab(tab) {
      this.activeTab = tab;
    },
    async fetchFile(url) {
      if (!url) {
        return;
      }

      try {
        const response = await integrations.searchFileByUrl(url);
        const contentType = response.headers['content-type'];
        if(contentType === 'text/xml') {

          const jsonResult = this.xmlToJson(response.data);
              
          const largestArray = this.findLargestArray(jsonResult);
  
          this.uploadFiles = largestArray;
          this.fileColumns = largestArray[0] ? Object.keys(largestArray[0]) : [];
        }
      } catch (error) {
        this.isUploadInProgress = true;
        this.isErrorLoadingFile = true;
      }
    },
    convertToObjects(header, data) {
  return data.slice(1).map(item => {
    const obj = {};
    header.forEach((key, index) => {
      obj[key] = item[index] || null;
    });
    return obj;
  });
},
    processXLSX(file) {
      const reader = new FileReader();
      reader.onload = e => {
        try {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: 'array' });
          const firstSheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[firstSheetName];

          const jsonSheet = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
          this.fileColumns = jsonSheet[0] || [];
          const formattedValues = this.convertToObjects(this.fileColumns,jsonSheet)
          this.uploadFiles = formattedValues;
        } catch (error) {
          this.typeFile = 'xlsx';
          this.isUploadInProgress = true;
          this.isErrorLoadingFile = true;
        }
      };
      reader.readAsArrayBuffer(file);
    },
    xmlToJson(xmlString) {
      
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(xmlString, "application/xml");

        const parseNode = (node) => {
          
          if (node.nodeType === Node.TEXT_NODE) {
            return node.nodeValue.trim();
          }

          const jsonNode = {};
          
          if (node.attributes) {
            for (let attr of node.attributes) {
              
              jsonNode[attr.nodeName] = attr.nodeValue;
            }
          }
          for (let child of node.childNodes) {
            const childData = parseNode(child);
            
            if (child.nodeType === Node.TEXT_NODE && childData) {
              return childData;
            }

            if (child.nodeType === Node.ELEMENT_NODE) {
              if (!jsonNode[child.nodeName]) {
                jsonNode[child.nodeName] = childData;
              } else if (Array.isArray(jsonNode[child.nodeName])) {
                jsonNode[child.nodeName].push(childData);
              } else {
                jsonNode[child.nodeName] = [jsonNode[child.nodeName], childData];
              }
            }
          }

          return jsonNode;
        }
        try {
          const xmlParse = parseNode(xmlDoc.documentElement)
        return xmlParse;
        } catch (error) {
          console.log(error, 'new error')
        }

    },

    findLargestArray(jsonObj) {

      let largestArray = [];

      const searchArray = (obj) => {
        for (const key in obj) {
          if (Array.isArray(obj[key])) {
            if (obj[key].length > largestArray.length) {
              largestArray = obj[key];
            }
          } else if (typeof obj[key] === 'object' && obj[key] !== null) {
            searchArray(obj[key]);
          }
        }
      };
      searchArray(jsonObj);
      if (largestArray.length === 0 && typeof jsonObj === 'object') {
        const values = Object.values(jsonObj);
        return Array.isArray(values) ? values : [values];
      }

      return largestArray;
    },
    processXML(file) {
      const reader = new FileReader();
      reader.onload = e => {
        try {
          const xmlString = e.target.result;

          if (file && file.type === "text/xml") {

            const jsonResult = this.xmlToJson(xmlString);
            const largestArray = this.findLargestArray(jsonResult);
            this.uploadFiles = largestArray;
            
            this.fileColumns = largestArray[0] ? Object.keys(largestArray[0]) : [];
            
            if (!this.fileColumns.length) {
              return this.showAlert('Certifique-se que as tags XML estejam de acordo com o exemplo.');
            }
          } else {
            this.showAlert("Por favor, selecione um arquivo XML válido.");
          }
        } catch (error) {
          this.typeFile = 'xml';
          this.isUploadInProgress = true;
          this.isErrorLoadingFile = true;
        }
      };
      reader.readAsText(file);
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      if (!file) return;

      const reader = new FileReader();
      this.fileName = file.name;

      const isXLSX =
        file.type ===
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      const isXML = file.type === 'text/xml' || file.type === 'application/xml';

      if (isXLSX) {
        this.processXLSX(file, reader);
      } else if (isXML) {
        this.processXML(file, reader);
      } else {
        this.showAlert('Formato de arquivo não suportado.');
      }
    },
    setIsUploadInProgress(value) {
      // eslint-disable-next-line no-return-assign
      return (this.isUploadInProgress = value);
    },
    setIsErrorLoadingFile(value) {
      // eslint-disable-next-line no-return-assign
      return (this.isErrorLoadingFile = value);
    },
    setNotEspaceInDisc(value) {
      // eslint-disable-next-line no-return-assign
      return (this.notEspaceInDisc = value);
    },
    resetState() {
      this.activeTab = 'link';
      this.file = [];
      this.typeFile = 'xlsx';
      this.fileColumns = [];
      this.contentOfUploadedFile = [];
      this.fileName = '';
      this.notEspaceInDisc = false;
      this.isErrorLoadingFile = false;
      this.isUploadInProgress = false;
    },
    onClose() {
      this.resetState();
      this.$emit('on-close');
    },
  },
};
</script>

<style scoped />

<template>
  <form @submit.prevent="updateCatalogProduct()">
    <div class="form-container">
      <label>
        {{ $t('INTEGRATION_APPS.LIST_CATALOG_PRODUCT.NAME_PRODUCT') }}
        <input
          v-model="name_product"
          type="text"
          :placeholder="
            $t('INTEGRATION_APPS.LIST_CATALOG_PRODUCT.NAME_PRODUCT')
          "
          class="small-input"
        />
      </label>
      <label>
        {{ $t('INTEGRATION_APPS.LIST_CATALOG_PRODUCT.URL_PRODUCT') }}
        <input
          v-model="link_product"
          type="text"
          :placeholder="$t('INTEGRATION_APPS.LIST_CATALOG_PRODUCT.URL_PRODUCT')"
          class="small-input"
        />
      </label>
      <label>
        {{ $t('INTEGRATION_APPS.LIST_CATALOG_PRODUCT.IMAGE_PRODUCT') }}
        <input
          v-model="url_image"
          type="text"
          :placeholder="
            $t('INTEGRATION_APPS.LIST_CATALOG_PRODUCT.IMAGE_PRODUCT')
          "
          class="small-input"
        />
      </label>
      <div class="buttons">
        <button
          :disabled="!name_product || !url_image || !link_product"
          class="button bg-primary primary mt-2"
        >
          <span class="button__content">
            {{ $t('INTEGRATION_APPS.LIST_CATALOG_PRODUCT.EDIT_PRODUCT') }}
          </span>
        </button>
        <button class="button clear btn-primary" @click.prevent="onClose">
          {{ $t('INTEGRATION_APPS.ADD.FORM.CANCEL') }}
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import alertMixin from 'shared/mixins/alertMixin';

export default {
  mixins: [alertMixin],
  props: {
    catalogProduct: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      name_product: '',
      url_image: '',
      link_product: '',
    };
  },
  mounted() {
    this.fetchCatalogProduct();
  },
  methods: {
    onClose() {
      this.$emit('on-close');
    },
    fetchCatalogProduct() {
      this.name_product = this.catalogProduct.name_product;
      this.url_image = this.catalogProduct.url_image;
      this.link_product = this.catalogProduct.link_product;
    },
    async updateCatalogProduct() {
      try {
        await this.$store
          .dispatch('integrations/updateCatalogProductBase', {
            ...this.catalogProduct,
            name_product: this.name_product,
            url_image: this.url_image,
            link_product: this.link_product,
          })
          .then(() => {
            this.showAlert(this.$t('Atualizado com sucesso!'));
            this.onClose();
          });
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style scoped></style>

/* global axios */

import ApiClient from './ApiClient';

class IntegrationsAPI extends ApiClient {
  constructor() {
    super('integrations/apps', { accountScoped: true });
  }

  connectSlack(code) {
    return axios.post(`${this.baseUrl()}/integrations/slack`, {
      code: code,
    });
  }

  createCatalogProductBase(catalogProductBaseData) {
    return axios.post(
      `${this.baseUrl()}/integrations/catalog_product_bases`,
      catalogProductBaseData
    );
  }

  updateCatalogProductBase(catalogProductBaseData) {
    return axios.patch(
      `${this.baseUrl()}/integrations/catalog_product_bases/${
        catalogProductBaseData.id
      }`,
      catalogProductBaseData
    );
  }

  deleteCatalogProductBase(catalogProductBaseId) {
    return axios.delete(
      `${this.baseUrl()}/integrations/catalog_product_bases/${catalogProductBaseId}`
    );
  }

  createKnowledgeBase(knowledgeBaseData) {
    return axios.post(
      `${this.baseUrl()}/integrations/knowledge_bases`,
      knowledgeBaseData
    );
  }

  getCatalogProductBases( page, hookId = null, name = '' ) {
    return axios.get(`${this.baseUrl()}/integrations/catalog_product_bases`, {
      params: { id: hookId, page, name },
    });
  }

  updateKnowledgeBase(knowledgeBaseData) {
    return axios.patch(
      `${this.baseUrl()}/integrations/knowledge_bases/${knowledgeBaseData.id}`,
      knowledgeBaseData
    );
  }

  deleteKnowledgeBase(knowledgeBaseId) {
    return axios.delete(
      `${this.baseUrl()}/integrations/knowledge_bases/${knowledgeBaseId}`
    );
  }

  delete(integrationId) {
    return axios.delete(`${this.baseUrl()}/integrations/${integrationId}`);
  }

  createHook(hookData) {
    return axios.post(`${this.baseUrl()}/integrations/hooks`, hookData);
  }

  deleteHook(hookId) {
    return axios.delete(`${this.baseUrl()}/integrations/hooks/${hookId}`);
  }

  updateHookSettings(hookId, settingsData) {
    return axios.patch(
      `${this.baseUrl()}/integrations/catalog_product_bases/${hookId}/update_settings`,
      settingsData
    );
  }

  searchFileByUrl(url){
    return axios.get(`${this.baseUrl()}/search/proxy_file?url=${url}`, {
      mode:'no-cors',
  }  )
  }
}

export default new IntegrationsAPI();

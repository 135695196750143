<template>
  <div class="row">
    <div class="small-8 columns with-right-space">
      <table v-if="hasConnectedHooks" class="woot-table">
        <thead>
          <th v-for="hookHeader in hookHeaders" :key="hookHeader">
            {{ hookHeader }}
          </th>
          <th v-if="isHookTypeInbox">
            {{ $t("INTEGRATION_APPS.LIST.INBOX") }}
          </th>
        </thead>
        <tbody>
          <tr v-for="hook in hooks" :key="hook.id">
            <td v-for="property in hook.properties" :key="property" class="hook-item">
              {{ property }}
            </td>
            <td v-if="isHookTypeInbox" class="hook-item">
              {{ inboxName(hook) }}
            </td>
            <td class="button-wrapper">
              <woot-button v-tooltip.top="$t('INTEGRATION_APPS.LIST.KNOWLEDGE_BASE.BUTTON_TEXT')" variant="smooth"
                color-scheme="alert" size="tiny" icon="document" class-names="success" @click="openListModal(hook)" />
              <woot-button v-tooltip.top="$t('INTEGRATION_APPS.LIST.DELETE.BUTTON_TEXT')" variant="smooth"
                color-scheme="alert" size="tiny" icon="dismiss-circle" class-names="grey-btn"
                @click="$emit('delete', hook)" />
            </td>
          </tr>
        </tbody>
      </table>
      <p v-else class="no-items-error-message">
        {{
        $t("INTEGRATION_APPS.NO_HOOK_CONFIGURED", {
            integrationId: this.labelContentEmpty,
        })
        }}
      </p>
    </div>
    <div class="small-4 columns">
      <p>
        <b>{{ integration.name }}</b>
      </p> 
      <p v-dompurify-html="
                    $t(
                      `INTEGRATION_APPS.SIDEBAR_DESCRIPTION.${integration.name.toUpperCase()}`,
                      { installationName: globalConfig.installationName }
                    )
                  " />
    </div>
    <div v-if="showListModal" @click="closeListModal">
      <woot-modal :show.sync="showListModal" :on-close="closeListModal" :persistent="true" size="modal-medium"
        @click.stop>
        <knowledge-base-list v-if="showListModal" :hook="selectedHook" @on-send="onSendListMessage"
          @cancel="closeListModal" @on-submit="onSendListMessage" class="custom-modal-size">
        </knowledge-base-list>
      </woot-modal>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import hookMixin from "./hookMixin";
import KnowledgeBaseList from "./KnowledgeBaseList.vue";

export default {
  mixins: [hookMixin],
  components: {
    KnowledgeBaseList,
  },
  data() {
    return {
      textList: "",
      showListModal: false,
      alertMessage: "",
    };
  },
  props: {
    integration: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters({
      globalConfig: "globalConfig/get",
      getAccount: 'accounts/getAccount',
    }),
    hookHeaders() {
      return this.integration.visible_properties;
    },
    hooks() {
      if (!this.hasConnectedHooks) {
        return [];
      }
      const { hooks } = this.integration;
      return hooks.map((hook) => ({
        ...hook,
        id: hook.id,
        properties: this.hookHeaders.map((property) =>
          hook.settings[property] ? hook.settings[property] : "--"
        ),
      }));
    },
    labelContentEmpty() {
      return this.integration.id === 'catalog'
        ? this.$t('INTEGRATION_APPS.CATALOG')
        : this.integration.id;
    },
  },
  methods: {
    handleOverlayClick(event) {
      if (event.target.classList.contains('modal-overlay')) {
        this.closeListModal();
      }
    },
    showAlert(message) {
      this.alertMessage = message;
    },
    openListModal(hook) {
      this.selectedHook = hook;
      this.showListModal = true;
    },
    closeListModal() {
      this.showListModal = false;
    },
    inboxName(hook) {
      return hook.inbox ? hook.inbox.name : "";
    },
    async onSendListMessage(payload) {
      try {
        await this.$store.dispatch(
          "integrations/createHookKnowledgeBase",
          payload
        );
        this.alertMessage = this.$t("INTEGRATION_APPS.ADD.API.SUCCESS_MESSAGE");
      } catch (error) {
        const errorMessage = error?.response?.data?.message;
        this.alertMessage =
          errorMessage || this.$t("INTEGRATION_APPS.ADD.API.ERROR_MESSAGE");
      } finally {
        this.showAlert(this.alertMessage);
      }
    },
  }
};
</script>

<style scoped lang="scss">
.hook-item {
  word-break: break-word;
}

.full-screen-modal-list {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.custom-modal-size {
  width: 95%;
  height: 90vh;
}
</style>

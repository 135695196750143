<template>
  <woot-modal :show="showCatalogModal" :on-close="() => $emit('on-close')">
    <woot-modal-header>
      <ul class="tabs">
        <li
          :class="{
            'tabs-title': true,
            'active-tab': activeTab === 'spreadsheet',
          }"
        >
          <a @click="() => setActiveTab('spreadsheet')">
            <span>
              {{
                $t(
                  'INTEGRATION_APPS.MODAL_CATALOG_INSERT_DATA.LOAD_SPREADSHEET'
                )
              }}
            </span>
          </a>
        </li>
        <li
          :class="{
            'tabs-title': true,
            'active-tab': activeTab === 'link',
          }"
        >
          <a @click="() => setActiveTab('link')">
            <span>
              {{ $t('INTEGRATION_APPS.MODAL_CATALOG_INSERT_DATA.INSERT_LINK') }}
            </span>
          </a>
        </li>
      </ul>
    </woot-modal-header>

  
    <p v-if="activeTab === 'spreadsheet'">
      {{ $t('INTEGRATION_APPS.IMPORT_FILE.DESC') + ' ' }}
      <a class="btn-primary" :href="xmlUrl" download="catalog.xml"> XML |</a>
      <a class="btn-primary" :href="xlsxUrl" download="catalog.xlsx"> XLSX</a>
    </p>

    <p v-if="activeTab === 'link'">
      {{ $t('INTEGRATION_APPS.IMPORT_FILE.DESC') + ' ' }}
      <a class="btn-primary" :href="xmlUrl" download="catalog.xml"> XML</a>
    </p>
    <div v-if="activeTab === 'spreadsheet'" class="file-upload-container">
      <label for="file-upload" class="file-upload-button">
        {{ $t('INTEGRATION_APPS.MODAL_CATALOG_INSERT_DATA.SELECT_FILE') }}
      </label>

      <input
        id="file-upload"
        type="file"
        accept=".xlsx, .xml"
        @change="triggerParentMethod"
      />
    </div>
    <div v-if="activeTab === 'link'" class="box-upload">
      <woot-input
        v-model="localLinkDownloadFile"
        :placeholder="
          $t(
            'INTEGRATION_APPS.MODAL_CATALOG_INSERT_DATA.PLACEHOLDER_INPUT_LINK'
          )
        "
      />
      <woot-button @click.prevent="handleFetchFile">
        {{ $t('INTEGRATION_APPS.MODAL_CATALOG_INSERT_DATA.BUTTON') }}
      </woot-button>
    </div>
  </woot-modal>
</template>
<script>
export default {
  props: {
    showCatalogModal: {
      type: Boolean,
      default: true,
    },
    linkDownloadFile: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      activeTab: 'spreadsheet',
      localLinkDownloadFile: this.linkDownloadFile,
    };
  },
  computed: {
    xmlUrl() {
      return '/downloads/catalog.xml';
    },
    xlsxUrl() {
      return '/downloads/catalog.xlsx';
    },
  },
  watch: {
    linkDownloadFile(newVal ) {
      this.localLinkDownloadFile = newVal;
    },
  },
  methods: {
    setActiveTab(tab) {
      this.activeTab = tab;
    },
    triggerParentMethod(event) {
      this.$emit('on-file-change', event);
    },
    async handleFetchFile() {
      this.$emit('on-fetch-file', this.localLinkDownloadFile);
    },
  },
};
</script>

<style scoped>
.tabs {
  display: flex;
  list-style: none;
  padding: 0;
}

.tabs-title {
  margin-right: 10px;
  padding: 0px 15px;
  cursor: pointer;
  border-radius: 5px;
  color: #fff;
  font-weight: bolder;
  transition: background-color 0.3s ease;
}

.tabs-title a {
  color: black;
}

.active-tab {
  background-color: #f5f5f5;
  color: #00c1d4 !important;
  font-weight: bolder;
}
.active-tab a {
  color: #00c1d4 !important;
}

.page-top-bar {
  padding: 1.2rem;
}

.box-upload {
  padding: 1.8rem;
}

.file-upload-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 65px;
  flex-direction: column;
  margin: 21px;
}

input[type='file'] {
  display: none;
}

.file-upload-button {
  display: inline-block;
  padding: 5px 28px;
  background-color: #00c1d4;
  color: white;
  cursor: pointer;
  border-radius: 8px;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  transition: background-color 0.3s ease;
}

.file-upload-button:hover {
  background-color: #009fb1;
}

.modal-container p {
  font-size: 1.4rem;
  margin: 10px 0px 0px 25px;
  padding: 0;
}
</style>
